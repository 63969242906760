import 'cocoon-js';


document.addEventListener("DOMContentLoaded", function(){
  if (document.querySelector('.edit_factory_event') || document.querySelector('.new_factory_event')) {
    let buttons = document.querySelectorAll('.remove');

    buttons.forEach((b) => {
      b.addEventListener('click', (e) => {
        e.preventDefault();
        const card = e.target.closest('.card');
        const destroyInput = e.target.closest('.inputs').querySelector('.hidden').querySelector('input');
        destroyInput.value = '1'
        card.remove()
      })
    })
  }
});