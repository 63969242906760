<template lang="pug">
form(@submit.prevent="submitForm", id="kyc_form")
  .row
    .col.s12
      .row
        .col.m6.s12
          label(for="name") Name
          input#name(type="text", v-model="form.name")
          span.red-text(v-if="errors.name") {{ errors.name }}
        .col.m6.s12
          label(for="dob") Date of Birth
          input#dob(type="date", v-model="form.dob")

  .row
    .col.m6.s12
      .row
        .col.s12
          label(for="email") Email
          input#email(type="email", v-model="form.email" disabled)
          span.red-text(v-if="errors.email") {{ errors.email }}
    .col.m6.s12
      .row
        .col.s12
          label(for="mobile") Mobile
          input#mobile(type="text", v-model="form.mobile" disabled)
          span.red-text(v-if="errors.mobile") {{ errors.mobile }}

  .row
    .col.m6.s12
      .row
        .col.s12
          label(for="address") Full Address
          input#autocomplete(type="text", v-model="form.full_address")
          span.red-text(v-if="errors.full_address") {{ errors.full_address }}
    .col.m6.s12
      .row
        .col.s12
          label(for="eircode") Eircode
          input#eircode(type="text", v-model="form.eircode")
          span.red-text(v-if="errors.eircode") {{ errors.eircode }}

  .card-panel
    .row
      .col.m6.s12
        template(v-if="form.identity_proof_url")
          h6.mt-1.black-text Photo ID is attached
          i.green-text.fa-solid.fa-check.medium
        span(v-else)
          h5.mt-1.black-text Please attach a photo ID

          p
            i.fa.fa-info-circle
            span &nbsp;
            span This can be a passport, driving license or any other government issued photo ID
          p
            i.fa.fa-info-circle
            span &nbsp;
            span Make sure the image is clear and all details are visible

      .col.m6.s12(v-if="form.identity_proof_url && !editingIdentityProof")
        a(:href="form.identity_proof_url", target="_blank")
          img(:src="form.identity_proof_url", width="100x100")
          br
          span View uploaded photo ID
          span &nbsp;
        br
        a.btn.btn-small(v-if="!editingIdentityProof" @click="editingIdentityProof = true", target="_blank") Edit
      .col.m6.s12(v-else)
        .row
          .col.s6
            input#identity_proof.ml-1.center-align(type="file", @change="handleFileUpload($event, 'identity_proof')")
            br
            a.btn.btn-small.mt-2(v-if="editingIdentityProof" @click="editingIdentityProof = false", target="_blank") Cancel Edit
        .red-text(v-if="errors.identity_proof") {{ errors.identity_proof }}

  .card-panel
    .row
      .col.m6.s12
        template(v-if="form.address_proof_url")
          h6.mt-1.black-text Proof of address is attached
          i.green-text.fa-solid.fa-check.medium
        template(v-else)
          h5.mt-1.black-text Please attached proof of address
          p
            i.fa.fa-info-circle
            span &nbsp;
            span This can be a utility bill, club letter or any other government issued document
          p
            i.fa.fa-info-circle
            span &nbsp;
            span Make sure the image is clear and all details are visible
      .col.m6.s12(v-if="form.address_proof_url && !editingAddressProof")
        .row
            .col.s12
              a(:href="form.address_proof_url", target="_blank")
                img(:src="form.address_proof_url", width="100x100")
                br
                span View uploaded proof of address
                span &nbsp;
              br
              a.btn.btn-small(v-if="!editingAddressProof" @click="editingAddressProof = true", target="_blank") Edit
      .col.m6.s12(v-else)
        .row
          .col.s6
            input#address_proof.ml-1(type="file", @change="handleFileUpload($event, 'address_proof')")
            br
            a.btn.btn-small.mt-2(v-if="editingAddressProof" @click="editingAddressProof = false", target="_blank") Cancel Edit
        .red-text(v-if="errors.address_proof") {{ errors.address_proof }}}

  .row
    .col.s12.center
      .card-panel
          b Are you a Politically Exposed Person (PEP)?
          br
          label(for="pep_yes")
            input(type="radio" id="pep_yes" value="true" v-model="form.pep")
            span  &nbsp; Yes  &nbsp;
          label(for="pep_no")
            input(type="radio" id="pep_no" value="false" v-model="form.pep")
            span &nbsp; No  &nbsp;
          br
          small
            span This is defined as an individual who is or has been entrusted with a prominent public function.
            span This includes heads of state, senior politicians, senior government, judicial or military officials,
            span senior executives of state owned corporations, important political party officials, etc.


  .card-panel
    .row
      .col.s12.center
        b Enhanced Due Diligence
        br
        small It may be necessary to provide proof of the source of funds used for betting. This can be a recent bank statement or payslip. This is to ensure that the funds used for betting are from a legitimate source. We maybe required to ask for additional information to verify the source of funds depending on the level of activity on your account.
        br
        small.green-text This setting is applied by system administrators only.
        br
        label(for="enhanced_due_diligence_yes")
            input(type="radio" id="enhanced_due_diligence_yes" value="true" v-model="form.enhanced_due_diligence" disabled)
            span  &nbsp; Yes  &nbsp;
        label(for="enhanced_due_diligence_no")
          input(type="radio" id="enhanced_due_diligence_no" value="false" v-model="form.enhanced_due_diligence" disabled)
          span &nbsp; No  &nbsp;
    .row.mt-4(v-if="form.enhanced_due_diligence")
      .col.m6.s12
        template(v-if="form.source_of_funds_proof_url")
          h6.mt-1.black-text Source of funds proof is attached
          i.green-text.fa-solid.fa-check.medium
        template(v-else)
          h5.mt-1.black-text Please attached source of funds proof
          p
            i.fa.fa-info-circle
            span &nbsp;
            span This can be a recent bank statements or payslips.
          p
            i.fa.fa-info-circle
            span &nbsp;
            span Make sure the image is clear and all details are visible
      .col.m6.s12(v-if="form.source_of_funds_proof_url && !editingSourceOfFundsProof")
        .row
            .col.s12
              a(:href="form.source_of_funds_proof_url", target="_blank")
                img(:src="form.source_of_funds_proof_url", width="100x100")
                br
                span View uploaded source of funds proof
                span &nbsp;
              br
              a.btn.btn-small(v-if="!editingSourceOfFundsProof" @click="editingSourceOfFundsProof = true", target="_blank") Edit
      .col.m6.s12(v-else)
        .row
          .col.s6
            input#address_proof.ml-1(type="file", @change="handleFileUpload($event, 'source_of_funds_proof')")
            br
            a.btn.btn-small.mt-2(v-if="editingSourceOfFundsProof" @click="editingSourceOfFundsProof = false", target="_blank") Cancel Edit
        .red-text(v-if="errors.source_of_funds") {{ errors.source_of_funds }}}


  .row
    .col.s12.center
      .card-panel
          small
            i.fa-solid.fa-info-circle
            span &nbsp;
            span By saving this form you agree to our &nbsp;
            a(href="/terms-of-use")
              span Terms of Use Policy
            span &nbsp; and &nbsp;
            a(href="/privacy-policy")
              span Privacy Policy
          br
          small.grey-text terms accepted at {{ form.terms_accepted_at }}

  .row.mt-4
    button.btn.btn-block(type="submit", :class="{ disabled: savingForm }")
      template(v-if="savingForm")
        i.fa.fa-spinner.fa-spin
        span &nbsp;
        span Saving
      template(v-else)
        | Save
    .card-panel.green(v-if="successMessage")
      i.fa.fa-check.white-text
      span &nbsp;
      span.white-text {{ successMessage }}
    .card-panel.red(v-if="errorMessage")
      i.fa.fa-times.white-text
      span &nbsp;
      span.white-text {{ errorMessage }}
</template>

<script>
import { reactive, toRaw, ref, watch } from 'vue';
import axios_client from '../utils/axios_client';
import { cloneDeep, debounce } from 'lodash';

export default {
  props: {
    kyc: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const savingForm = ref(false);
    const successMessage = ref('');
    const errorMessage = ref('');
    const kyc = JSON.parse(props.kyc);
    const editingAddressProof = ref(false);
    const editingIdentityProof = ref(false);
    const editingSourceOfFundsProof = ref(false);
    const showSourceOfFundsProofInputs = ref(false);

    const form = reactive({
      name: kyc.name,
      dob: kyc.dob,
      full_address: kyc.full_address,
      eircode: kyc.eircode,
      mobile: kyc.mobile,
      email: kyc.email,
      identity_proof_url: kyc.identity_proof_url,
      identity_proof: null,
      address_proof_url: kyc.address_proof_url,
      address_proof: null,
      source_of_funds_proof_url: kyc.source_of_funds_proof_url,
      source_of_funds_proof: null,
      terms_accepted: true,
      terms_accepted_at: kyc.terms_accepted_at,
      pep: kyc.pep,
      enhanced_due_diligence: kyc.enhanced_due_diligence,
    });

    const errors = reactive({
      name: '',
      full_address: '',
      eircode: '',
      terms_accepted: ''
    });

    const submitForm = async () => {
      errorMessage.value = '';
      successMessage.value = '';

      savingForm.value = true;
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const formData = new FormData();
      const formDeepCopy = cloneDeep(form);
      const plainForm = toRaw(formDeepCopy);
      delete plainForm.email;
      delete plainForm.mobile;
      delete plainForm.terms_accepted_at;
      delete plainForm.identity_proof_url;
      delete plainForm.address_proof_url;
      delete plainForm.source_of_funds_proof_url;

      for (const key in plainForm) {
        const value = plainForm[key];

        if (value instanceof File) {
          formData.append(`kyc[${key}]`, value);
        } else if (value !== null && typeof value === 'object') {
          formData.append(`kyc[${key}]`, JSON.stringify(value));
        } else {
          formData.append(`kyc[${key}]`, value);
        }
      }

      const url = window.location.href + '.json';


      axios_client.put(url, formData, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if(response.data.status === 'error') {
          errorMessage.value = response.data.message.join(', ');
        }

        if(response.data.status === 'success') {
          window.kyc = response.data.kyc;
          if(response.data.kyc.identity_proof_url) {
            form.identity_proof_url = response.data.kyc.identity_proof_url;
            editingIdentityProof.value = false;
          }

          if(response.data.kyc.address_proof_url) {
            form.address_proof_url = response.data.kyc.address_proof_url;
            editingAddressProof.value = false;
          }

          if(response.data.kyc.source_of_funds_proof_url) {
            form.source_of_funds_proof_url = response.data.kyc.source_of_funds_proof_url;
            editingSourceOfFundsProof.value = false;
          }

          successMessage.value = "KYC form saved successfully";
        }

        setTimeout(() => {
          savingForm.value = false;
        }, 1000);
      }).catch((error) => {
        console.error(error);
        setTimeout(() => {
          savingForm.value = false;
        }, 1000);
      });
    };

    const handleFileUpload = (event, type) => {
      const files = event.target.files;
      form[type] = files[0]
    };

    const autosaveForm = debounce(() => {
      // Assuming you have an autosave API endpoint or logic
      submitForm();
    }, 5000);

    // Watchers for form data to trigger autosave
    watch(form, () => {
      if(savingForm.value === false) {
        autosaveForm();
      }
    }, {
      deep: true // This is necessary to track deep changes within the object
    });

    return {
      successMessage,
      errorMessage,
      savingForm,
      form,
      errors,
      submitForm,
      handleFileUpload,
      editingAddressProof,
      editingIdentityProof,
      editingSourceOfFundsProof,
      showSourceOfFundsProofInputs
    };
  },
};
</script>
