<template lang="pug">
.section
  .row
    .col.s12
      h1
        i.fa-sharp.fa-solid.fa-clipboard-question.left
        span Pending Bets
        span(v-if="slips.length") ({{ slips.length }})
  .row
    .col.s12
      div(v-if="loading")
        .center
            h4.teal-text
              i.fa.fa-spinner.fa-spin
              span &nbsp; Loading pending slips...
      div(v-else)
        .card-panel.blue-grey.darken-1.center.white-text(v-if="slips.length === 0")
          .center
            h4
              i.fa-solid.fa-inbox
              span &nbsp; No pending slips
        .row.hide-on-med-and-down(v-else)
          .col.s3.center
            b Slip/User
          .col.s6.center
            b Bets
          .col.s3.center
            b.right Stakes/Potential Payout

        div(v-for="slip in slips" :slip="slip" :key="slip.ean_code")
          div(v-for="bet in slip.bets" :bet="bet" :key="bet.id")
            Bet(:bet="bet" :slip="slip" :loadSlips="loadSlips")


</template>

  <script>
  import consumer from '../../channels/consumer';
  import { ref, onMounted } from 'vue';
  import axios_client from '../../utils/axios_client';
  import Bet from './bet.vue';

  export default {
    components: {
      Bet
    },
    setup() {
      const loading = ref(true);
      const slips = ref([]);

      const loadSlips = async () => {
        loading.value = true;
        const response = await axios_client.get('/slips/pending.json');
        slips.value = response.data.slips;
        loading.value = false;
        // update page title
        document.title = `Pending Bets (${slips.value.length})`;
        // update nav bar count
        const pendingBetsCountEl = document.querySelector('.pending-bets-count');
        pendingBetsCountEl.textContent = `(${response.data.slips.length})`;
      };

      onMounted(async () => {
        await loadSlips();
        consumer.subscriptions.create("PendingBetsChannel", {
          received(_) {
            loadSlips();
          },
        });
      });

      return {
        loading,
        slips,
        loadSlips
      };
    }
  };
  </script>
