function copyToClipboard() {
  // Clear any current selections
  window.getSelection().removeAllRanges();

  var copyText = document.getElementById("linkToCopy");

  // Create a range (a range is a selection of the document's text or other content)
  var range = document.createRange();

  // Select the text node of the copyText element
  range.selectNode(copyText);

  // Add that range to the selection
  window.getSelection().addRange(range);

  try {
    // Execute the copy command
    document.execCommand('copy');
  } catch (err) {
    // If there was an error (such as an exception thrown while trying to copy), log the error
    console.error('Oops, unable to copy. Error:', err);
  }

  // Clear the selection if you don't want the text to remain selected
  window.getSelection().removeAllRanges();
}

window.copyToClipboard = copyToClipboard;
