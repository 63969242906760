export default function useFormatCurrency() {
  const formatCurrency = (value) => {
    const currencySymbol = window.currencySymbol || '';
    // Check if the value is a whole number
    if (Number.isInteger(value)) {
      return `${currencySymbol}${value}`;
    } else {
      // Format the value to 2 decimal places
      const val = (value / 1).toFixed(2);
      return `${currencySymbol}${val}`;
    }
  }
  return { formatCurrency }
}
