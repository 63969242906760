import './materialize'
import './admin/pending.js';
import { createApp } from 'vue';
import PriceRuleForm from './admin/price_rule_form.vue';
import BetLimitForm from './admin/bet_limit_form.vue';

import KycForm from './kyc/kyc_form.vue';

const app = createApp({});

app.component('price-rule-form', PriceRuleForm);
app.component('bet-limit-form', BetLimitForm);
app.component('kyc-form', KycForm);

const vueBehaviorEl = document.querySelector('[data-behavior="vue"]');

if (vueBehaviorEl) {
  document.addEventListener('DOMContentLoaded', () => {
    app.mount('[data-behavior="vue"]');
  });
}
