<template lang="pug">
form(@submit.prevent="submitForm")
  #error_explanation.red-text(v-if="errors.length > 0")
    ul
      li(v-for="error in errors") {{ error }}
  .row
    .col.s12
      .form-inputs
        label(for="rule_id") Rule ID
        select(v-model="formData.rule_id" class="browser-default")
          option(v-for="(label, value) in JSON.parse(rule_mappings)" :value="value") {{ label[0] }}
  .row
    .col.s12
      .form-inputs
        label(for="price_ruleable_type") Price Ruleable Type
        select(v-model="formData.price_ruleable_type" class="browser-default" @change="selectRuleableType")
          option(v-for="type in priceRuleableTypes") {{ type }}
  .row
    .col.s12
      .form-inputs
        label(for="categories") Category
        select(v-model="formData.category_id" class="browser-default" @change="selectCategory")
          option(v-for="category in categories" :value="category.id") {{ category.name }}
  .row(v-if="formData.price_ruleable_type !== 'Category'")
    .col.s12
      .form-inputs
        label(for="events") Event
        select(v-model="formData.event_id" class="browser-default" @change="selectEvent")
          option(v-for="event in events" :value="event.id") {{ event.name }} {{ event.action_date }}
  .row(v-if="formData.price_ruleable_type === 'Market'")
    .col.s12
      .form-inputs
        label(for="market") Market
        select(v-model="formData.market_id" class="browser-default")
          option(v-for="market in markets" :value="market.id") {{ market.name }} {{ moment(market.scheduled_off_time).format('DD/MM/YY h:mm') }}
  br
  br
  .row
    .col.s12
      .form-inputs
        button(type="submit" class="btn btn-block") Save
  br
  br
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import axios_client from '../utils/axios_client';
import moment from 'moment';

export default {
  props: {
    user_id: {
      type: String,
      required: true,
    },
    rule_mappings: {
      type: String,
      required: true,
    },
    price_rule: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const formData = reactive({
      rule_id: 0,
      price_ruleable_type: 'Category',
      price_ruleable_id: '',
      category_id: 0,
      event_id: null,
      market_id: null,
    });

    const errors = ref([]);
    const categories = ref([]);
    const events = ref([]);
    const markets = ref([]);
    const priceRuleableTypes = ref(['Category', 'Event', 'Market']);

    const fetchCategories = () => {
      axios_client.get('/categories.json').then((response) => {
        categories.value = response.data;
      });
    };

    const fetchEvents = (categoryId) => {
      axios_client.get(`/categories/${categoryId}/events.json`).then((response) => {
        events.value = response.data;
      });
    };

    const fetchMarkets = (eventId) => {
      axios_client.get(`/events/${eventId}/markets.json`).then((response) => {
        markets.value = response.data;
      });
    };

    const selectRuleableType = () => {
      if (formData.price_ruleable_type === 'Category') {
        if (categories.value.length === 0) {
          fetchCategories();
        }
      }
      selectCategory();
      selectEvent();
    };

    const selectCategory = () => {
      if (formData.price_ruleable_type !== 'Category' && formData.category_id !== null) {
        fetchEvents(formData.category_id);
      }
    };

    const selectEvent = () => {
      if (formData.price_ruleable_type === 'Market' && formData.event_id !== null) {
        fetchMarkets(formData.event_id);
      }
    };

    const submitForm = () => {
      switch (formData.price_ruleable_type) {
        case 'Category':
          formData.price_ruleable_id = formData.category_id;
          break;
        case 'Event':
          formData.price_ruleable_id = formData.event_id;
          break;
        case 'Market':
          formData.price_ruleable_id = formData.market_id;
          break;
      }

      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      if (!props.price_rule) {
        axios_client.post(`/users/${props.user_id}/price-rules.json`,
          { price_rule: formData },
          { headers: { 'X-CSRF-Token': csrfToken } })
          .then((response) => {
            window.location.href = `/users/${props.user_id}/price-rules/${response.data.id}`;
          }).catch((error) => {
            errors.value = error.response.data.messages;
          });
      } else {
        axios_client.put(`/users/${props.user_id}/price-rules/${formData.id}.json`,
          { price_rule: formData },
          { headers: { 'X-CSRF-Token': csrfToken } })
          .then((response) => {
            window.location.href = `/users/${props.user_id}/price-rules/${response.data.id}`;
          }).catch((error) => {
            errors.value = error.response.data.messages;
          });
      }
    };

    onMounted(() => {
      fetchCategories();
      if (props.price_rule) {
        Object.assign(formData, JSON.parse(props.price_rule));
        selectCategory();
        selectEvent();
      }
    });

    return {
      formData,
      errors,
      categories,
      events,
      markets,
      priceRuleableTypes,
      selectRuleableType,
      selectCategory,
      selectEvent,
      submitForm,
      moment,
    };
  },
};
</script>
