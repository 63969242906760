const flashMessage = (_context, args) => {
  const displayLength = args.displayLength || 2000;
  M.toast({
    html: args.message,
    displayLength,
    classes: args.class,
  });
};

export default flashMessage;
