import axios_client from './axios_client'

function fetchAccount() {
  let userPresent = document.querySelector('body').dataset.userpresent === 'true'
  let hideFreshChat = document.querySelector('body').dataset.hidefreshchat === 'true'
  if (userPresent && !hideFreshChat) {
    axios_client.get('/users/current.json').then(function(response) {
      window.userEmail = response.data.email
      window.externalId = response.data.id
      window.userAdmin = response.data.admin
    }).then(function() {
      if (window.externalId && !window.userAdmin) {
        initiateCall()
      }
    })
  }
}

function initFreshChat() {
  window.fcWidget.init({
    token: "5f6b15f9-3194-4e6a-a78b-3d6c0d7b9740",
    host: "https://wchat.freshchat.com",
    email: window.userEmail,
    firstName: window.userEmail,
    externalId: window.externalId
  });
}

function initialize(i, t) {
  var e;
  i.getElementById(t) ? initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0, e.src = "https://wchat.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e))
}

function initiateCall() {
  initialize(document, "freshchat-js-sdk")
}

window.addEventListener ? window.addEventListener("load", fetchAccount, !1) : window.attachEvent("load", fetchAccount, !1);
