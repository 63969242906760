function Reporter() {
  this.csvDownload = document.getElementById("csv-download");
  this.htmlReport = document.getElementById("html-report");
  this.selected = document.getElementById("date-selector");
  this.customDateRange = document.getElementById("custom-date-range");

  this.bindEvents = function() {
    this.csvDownload.addEventListener("click", (event) => {
      var form = event.target.form;
      event.preventDefault();
      form.action = form.action.replace(".html", ".csv");
      form.submit();
    });

    this.htmlReport.addEventListener("click", (event) => {
      var form = event.target.form;
      event.preventDefault();
      form.action = form.action.replace(".csv", ".html");
      form.submit();
    });
  }

  this.customDateFilter = function() {
    var value = this.selected.options[this.selected.selectedIndex].value;
    if (value === "Custom") {
      this.customDateRange.style.display = "block";
    } else {
      this.customDateRange.style.display = "none";
    }
  }
}

if(document.getElementById('slips')) {
  window.reporting = new Reporter()
  window.reporting.bindEvents()
}