import Pending from './pending/pending.vue';
import { createApp } from 'vue';

const app = createApp(Pending);

const element = document.getElementById('pending')

if (element) {
  app.mount(element);
}
