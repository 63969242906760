import Chart from 'chart.js/auto';


var off_time_analysis_graph = document.getElementById('off_time_analysis_graph');
if (off_time_analysis_graph) {
  new Chart(off_time_analysis_graph, {
    type: 'line',
    data: {
      labels: window.labels,
      datasets: [{
        label: 'Off Time Delays Seconds',
        data: window.data,
        borderColor: [
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1
      }]
    },
    options: {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }
  });
}

var fsbBetChartEl = document.getElementById('fsbBetChart')

const fsbBetsCountTotalChartData = window.fsbBetsCountTotalChartData;

if (fsbBetChartEl && fsbBetsCountTotalChartData) {
  const context = fsbBetChartEl.getContext('2d');
  var data = {
    labels: fsbBetsCountTotalChartData.map((item) => item[0]),
    datasets: [{
      label: 'Total FsbBet Bet Count per Date',
      data: fsbBetsCountTotalChartData.map((item) => item[1]),
      borderColor: 'rgba(75, 192, 192, 1)',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      fill: false
    }]
  };

  new Chart(context, {
    type: 'line',
    data: data,
  });
}