import Rails from 'rails-ujs'
import './utils/freshchat'
import './admin/factory/events.js'
import './admin/markets.js'
import './utils/reporting'
import './materialize'
import './utils/graph'
import './utils/copy_to_clipboard'
import './utils/barcode_display.js'
import './admin/pending_bets_nav'

Rails.start();

const datetimepickers = document.querySelectorAll('.datetimepicker');
const datetimepickersArray = Array.from(datetimepickers);
datetimepickersArray.forEach((datetimepicker) => {
  new TempusDominus(datetimepicker,
    {
      display: {
        icons: {
          type: 'icons',
          time: 'fa fa-circle',
          date: 'fa fa-calendar',
          up: 'fa fa-arrow-up',
          down: 'fa fa-arrow-down',
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-calendar-check',
          clear: 'fa fa-trash',
          close: 'fa fa-xmark'
        },
        components: {
          calendar: true,
          date: true,
          month: true,
          year: true,
          decades: true,
          clock: true,
          hours: true,
          minutes: true,
          seconds: true,
          useTwentyFourHour: undefined
        },
      }
    }
  );
});
