import M from 'materialize-css';

M.AutoInit();

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    M.AutoInit();
    M.Tabs.init(document.getElementById('state-filters'), {});
    M.Collapsible.init(document.querySelectorAll('.collapsible'), {});

    if (document.body.dataset.flash) {
      M.toast({
        html: document.body.dataset.flash,
        displayLength: 2000,
        classes: 'green white-text',
      });
    }
  }, 1000);
});
