import consumer from "../channels/consumer";
import flashMessage from "../utils/flash_message";
const pendingBetsCountEl = document.querySelector('.pending-bets-count');
const alert1Path = window.alert1Path

if (pendingBetsCountEl) {
  consumer.subscriptions.create("PendingBetsChannel", {
    received(data) {
      flashMessage({}, { message: 'New pending bet received!', class: 'green', displayLength: 1000 });
      const alertAudioObject = new Audio(alert1Path);
      alertAudioObject.play();
      // update the pending bets count
      pendingBetsCountEl.textContent = `(${data.pending_bets_count})`;
      pendingBetsCountEl.classList.add('shake');
      setTimeout(() => pendingBetsCountEl.classList.remove('shake'), 500);
    },
  });
}
