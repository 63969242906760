
if (document.querySelector('.edit_market')) {
  const setTimeButton = document.querySelector('#market_no_more_bets_now')
  const clearButton = document.querySelector('#market_no_more_bets_clear');
  const dateField = document.querySelector('#market_no_more_bets_time_date');
  const timeField = document.querySelector('#market_no_more_bets_time_time');

  setTimeButton.addEventListener('click', () => {
    const timeNow = new Date();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    dateField.value = `${months[timeNow.getMonth()]} ${timeNow.getDate()}, ${timeNow.getFullYear()}`; // Jan 1, 2020
    timeField.value = timeNow.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }); // 01:00 PM
  });

  clearButton.addEventListener('click', () => {
    dateField.value = '';
    timeField.value = '';
  });
}
