import { createConsumer } from "@rails/actioncable"

export default createConsumer(getWebSocketURL)

function getWebSocketURL() {
  let protocol = 'ws';
  if (location.protocol.indexOf('https') > -1) {
    protocol = 'wss';
  }
  const userIdParam = window.userId ? `user_id=${window.userId}` : '';
  const queryParams = userIdParam ? `?${userIdParam}` : '';
  return `${protocol}://${location.host}/cable${queryParams}`;
}