<template lang="pug">
.card
  .card-content
    .row
      .col.m3.s12.center
        .mb-2
          .chip(:class="slip.state") {{ slip.state }}
        .mb-2
          b {{ formatTime(bet.created_at) }}
        .mb-2
          a(:href="`/slips/${slip.ean_code}`")
            i.material-icons receipt
            span {{ slip.ean_code }}
        .mb-2
          a(:href="`/users/${slip.user.id}`")
            i.material-icons account_box
            span {{ slip.user.email }}
      .col.m5.s12.center
        .mb-2
          b {{ bet.bet_type }}
        div(v-for="selection in bet.selections" :selection="selection" :key="selection.id")
          .mb-2
            a(:href="'/events/' + selection.event.id + '/markets/' + selection.market.id + '/results'")
              span {{ formatTime(selection.market.scheduled_off_time) }} {{ selection.event.name }}
              span &nbsp; - &nbsp;
              span {{ selection.market.name }}
          .mb-2
            span(v-if="!selection.competitor.unnamed && selection.competitor.card_number")
              span &nbsp;
              span ({{ selection.competitor.card_number }})
            span &nbsp;
            b {{ selection.competitor.name }}
            .chip.green.ml-1.white-text {{ selection.price.fractional }}
      .col.m4.s12
        .mb-2
          template(v-if="editing")
            .input-field
              input(type="text" v-model="bet.unit_stake" :id="`unit_stake_${bet.id}`" ref="unit_stake" @keyup.enter="counterOffer(bet)")
              label(:for="`unit_stake_${bet.id}`") Unit Stake
          template(v-else)
            b Stake
            b.secondary-content {{ formatCurrency(bet.unit_stake) }}
            .mb-2
              b Potential Returns
              b.secondary-content {{ formatCurrency(bet.potential_returns) }}
        .mb-2
          template
          button.btn.btn-block(@click="toggleEdit" :class="editing ? 'amber black-text' : 'blue'")
            i.fa.fa-edit
            span &nbsp; {{ editing ? 'Cancel' : 'Edit' }}
        template(v-if="editing")
          .mb-2
            button.btn.btn-block.teal(@click="counterOffer(bet)")
              i.fa.fa-rotate-reverse
              span &nbsp; Counter Offer
          .mb-2
            button.btn.btn-block.teal(@click="updateToLaid(bet)")
              i.fa.fa-check-double
              span &nbsp; Update To Laid
        template(v-else)
          .mb-2
            button.btn.btn-block.green(@click="acceptBet(bet)")
              i.fa.fa-check
              span &nbsp; Accept
          .mb-2
            button.btn.btn-block.red(@click="rejectBet(bet)")
              i.fa.fa-times
              span &nbsp; Reject
  </template>

    <script>
    import flashMessage from '../../utils/flash_message';
    import axios_client from '../../utils/axios_client';
    import useFormatCurrency from '../../utils/format_currency';
    import moment from 'moment';
    import { ref } from 'vue';

    export default {
      props: {
        slip: Object,
        bet: Object,
        loadSlips: Function
      },
      setup(props) {
        const editing = ref(false);
        const { formatCurrency } = useFormatCurrency();

        const formatTime = (time) => {
          return moment(time).format('HH:mm:ss - DD/MM/YY');
        };

        const acceptBet = async (bet) => {
          await axios_client.get(`/bets/${bet.id}/accept.json`);
          props.loadSlips();
        };

        const rejectBet = async (bet) => {
          // flash a message that the bet has been rejected
          const response = await axios_client.get(`/bets/${bet.id}/reject.json`);
          if(response.data.state === 'rejected') {
            flashMessage({}, { message: 'Bet has been rejected', class: 'amber', displayLength: 5000 });
          } else {
            flashMessage({}, { message: 'Bet could not be rejected', class: 'red', displayLength: 5000 });
          }
          props.loadSlips();
        };

        const counterOffer = async (bet) => {
          const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
          axios_client.defaults.headers.common['X-CSRF-Token'] = csrfToken;
          const response = await axios_client.put(`/bets/${bet.id}/counter_offer.json`, { bet: { unit_stake: bet.unit_stake } });
          if(response.data.state === 'counter_offer') {
            flashMessage({}, { message: 'Bet has been updated', class: 'green', displayLength: 5000 });
          }
          editing.value = false;
          props.loadSlips();
        };

        const updateToLaid = async (bet) => {
          const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
          axios_client.defaults.headers.common['X-CSRF-Token'] = csrfToken;
          const response = await axios_client.put(`/bets/${bet.id}/update_to_laid.json`,  { bet: { unit_stake: bet.unit_stake } });
          if(response.data.state === 'laid') {
            flashMessage({}, { message: 'Bet has been updated to laid', class: 'green', displayLength: 5000 });
          }
          editing.value = false;
          props.loadSlips();
        };

        const toggleEdit = () => {
          // redirect to the edit page
          editing.value = !editing.value;
          // click on the input field
          if(editing.value) {
            setTimeout(() => {
              document.getElementById(`unit_stake_${props.bet.id}`).focus();
            }, 100);
          }
        };

        return {
          editing,
          toggleEdit,
          formatCurrency,
          formatTime,
          acceptBet,
          rejectBet,
          counterOffer,
          updateToLaid
        };
      }
    };
    </script>
